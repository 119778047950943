@import './themes';
// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@380&display=swap');

//***image content resolution*****//
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  a {
    text-decoration: none;
    color: inherit;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

body {
  font-family: 'Poppins', sans-serif;
  line-height: 1.7;
  font-size: .9rem;
}




td {
  &::first-letter{
    text-transform: capitalize;
  }
}
button {
  font-weight: 700 !important;
}

a {
  text-decoration: none;
}

li {
  list-style-type: none;
}

h3 {
  font-size: 1.5rem;
}

td{
  text-transform: lowercase;
  &::first-letter{
    text-transform: capitalize;
  }
}


.cart-icon{
  margin: '.-2rem 0rem 0rem  -0rem';
  color: $secondary2
}

.line {
  height: 0.3rem;
  background: $primary;
  border-radius: $border-radius;
  width: 7rem;
  margin: 0.5rem auto;
}

.color-black {
  color: $black;
}
.text-primary {
  color: $primary;
}

.secondary-color {
  color: $secondary2;
}

.all-btn {
  margin: 0 0.6rem 0 0;
  a {
    color: $white;
  }
}

//**default input fields****//

.notify {
  color: $white;
  background: rgb(250, 86, 86);
  margin: 2rem 0 0 0;
  padding: 0.7rem 0.5rem;
  border-radius: $border-radius;
}

.custom-btn {
  border-radius: 0.2rem;
  padding: 0.6rem 2rem;
  font-weight: 900;
  border: none;
  color: $white;
  cursor: pointer;
  &-primary {
    color: $black;
    background: $secondary;
    &:hover {
      background: rgba($secondary, 0.7);
    }
  }
  &-secondary {
    color: $white;
    background-color: $primary;
    &:hover {
      background: rgba($primary, 0.7);
    }
  }

  &-success {
    background: $success;
    &:hover {
      background: rgba($success, 0.9);
    }
  }
}

.line {
  height: 0.3rem;
  width: 3rem;
  margin: 0.7rem auto;
  background: $secondary;
  border-radius: $border-radius;
  &-primary {
    background: $primary;
  }
}

.loader {
  height: 100%;
  width: 100%;
}

.discount {
  position: absolute;
  background: $secondary;
  padding: 0.8rem 2rem;
  margin: 0.6rem 0;
  border-top-right-radius: 1.3rem;
  border-bottom-right-radius: 1.3rem;
  font-weight: 600;
  color: $primary;
  font-size: 1.2rem;
}

.heading {
  display: flex;
  justify-content: center;
  margin: 2.4rem 0 0 0;
  align-items: center;
  h3 {
    background: $black;
    color: $white;
    border-radius: $border-radius;
    padding: 0.6rem;
  }
  span {
    display: block;
    border-radius: $border-radius;
    background: $primary;
    height: 0.3rem;
    width: 5rem;
    margin: 0 0.4rem;
  }
}

//**cart Badge Icons ****//
.cartbadge {
  text-align: center;
  color: $white;
  background: $primary;
  padding: 0rem 0.6rem;
  position: absolute;
  top: 4.2rem;
  margin: -0.3rem 0.3rem;
  z-index: 3;
  border-radius: 50%;
  &-sm {
    top: -1.2rem;
    margin: 1.6rem 0;
    padding: 0rem 0.6rem;
  }
}

//****pagination*****//
.active-btn {
  background: $light2;
  color: $black;
  &:active {
    background: $secondary;
    border-radius: none;
    border: none;
    padding: 0.4rem 0.6rem;
  }
}

//*****carousel custom buttons*****//
.custom-arrow {
  position: absolute;
  top: 0rem;
  right: 0;
  margin: 1rem 3rem;
}

.slide-btn {
  background: rgba($black, 0.5);
  outline: none;
  border: none;
  border-radius: $border-radius;
  padding: 0.2rem 0.3rem;
  color: $white;
  margin: 0 0.5rem;
  cursor: pointer;
}

.sbtn-1 {
  left: 0;
}
.sbtn-2 {
  right: 0;
}

.btn-move {
  display: none;
  background: transparent;
  height: 3rem;
  width: 3rem;
  border: 2px solid $light;
  color: $white;
  border-radius: 50%;
  outline: none;
}

.btn-slide1,
.btn-slide2 {
  position: absolute;
  top: 7rem;
  margin: 0 4rem;
  // left: 0;
}
.btn-slide2 {
  left: 0;
}

.slide-btn:hover {
  background: rgba($black, 0.8);
}

// to be edited later please neglect this styling
.load-wrapp {
  background-color: #f4f4f4a2;
  height: 70vh;
}

.load-3 {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lineL {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #4b9cdb;
  margin: 0.4rem 0.2rem;
}

.load-3 .lineL:nth-last-child(1) {
  background: #008080;
  animation: loadingC 0.6s 0.1s linear infinite;
}
.load-3 .lineL:nth-last-child(2) {
  background: #ffcc2a;
  animation: loadingC 0.6s 0.2s linear infinite;
}
.load-3 .lineL:nth-last-child(3) {
  background: #008080;
  animation: loadingC 0.6s 0.3s linear infinite;
}
.load-3 .lineL:nth-last-child(4) {
  background: #ffcc2a;
  animation: loadingC 0.6s 0.4s linear infinite;
}
.load-3 .lineL:nth-last-child(5) {
  animation: loadingC 0.6s 0.5s linear infinite;
}

@keyframes loadingC {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, 0);
  }
}

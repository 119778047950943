@import './themes';
@import './config';

.top-message {
  border-bottom: 0.5px solid $light;
  padding: 0.2rem;
  display: none;
  justify-content: space-between;
  & > * {
    margin: 0.4rem 1.3rem;
  }
}

.largenav {
  display: none;
 font-style: normal;
  justify-content: space-around;
  // position: fixed;
  width: 100%;
  background: $white;
  z-index: 3;
  &-logo {
    margin: 1rem 0 0 0;
    flex-basis: 20%;
    text-align: center;
  }

  &-links {
    display: flex;
    margin: 0.5rem 0.3rem 0rem 0.8rem;
    padding: 1rem 0;
  }
  &-search {
    flex-basis: 40%;
    margin: 0.6rem 0 0rem 0rem;
    form {
      input {
        height: 2.5rem;
        border: 1px solid $light;
        border-radius: $border-radius;
        outline: none;
        width: 70%;
        background: none;
        padding: 0.8rem;
        margin: 0.7rem 0;
        &:focus {
          border: 1px solid $primary;
        }
      }
      button {
        margin: 0 0.3rem;
      }
    }
  }
}

.appbar {
  display: none;
}

@media (min-width: 1024px) {

  //***Home Page****//
  .menuicon {
    .side-nav-menu {
      display: none;
    }
  }

  //****carousel buttons****//
  .btn-move {
    display: block;
  }

  //***services****//
  .services {
    &-grid {
      display: grid;
      grid-template-columns: repeat(4, 0.4fr) !important;
    }
  }

  .top-message {
    display: flex;
    span {
      font-size: 0.8rem;
    }
  }
  .largenav {
    display: flex;
  }
  .appbar {
    display: block;
  }
  //***intersection Observer style
  .int-app {
    z-index: 4;
    top: 0;
    position: fixed;
    width: 100%;
    box-shadow: $box-shadow;
  }
  .navbar {
    display: none;
  }

  //****About Page*****//
  .about {
    &-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  //****help******//

  .help {
    &-img {
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        flex-basis: 30%;
      }
    }
  }

  //***contact-page**//
  .contact {
    &-imgicon {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
    &-form {
      div {
        margin: 1rem 0;
      }
      select {
        padding: 0.5rem;
      }
    }
  }

  .login-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row nowrap;
    & > div {
      width: 40%;
    }
  }

  .error {
    height: 20rem;
    display: flex;
    flex-flow: row-reverse;
    justify-content: space-evenly;
    align-items: center;
    div {
      h1,
      p,
      a {
        margin: 0.5rem;
        text-align: left;
      }
      &:nth-child(1) {
        img {
          height: 15rem;
        }
      }
    }
  }
}

@media (max-width: 768px) {

  h3 {
    font-size: $sm-font-heading;
  }

  h4 {
    font-size: $sm-font-heading;
  }
  p {
    font-size: $sm-font;
  }
  .contact {
    form {
      div {
        width: 100%;
      }
    }
  }
}

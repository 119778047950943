$border-radius: 0.3rem;
$primary: #008080;
$light: #e9e9e9;
$light2: #f0f0f0;
$grey: #383838;
$white: #fff;
$secondary: #ffcc2a;
$footer-text: rgb(209, 209, 209);
$bg-color: #fff;
$gray: rgb(63, 63, 63);
$black: rgb(22, 22, 22);
$success: green;
$secondary2: #ee6211;
$danger: #db0012;
$sm-font: 0.9rem;
$cardBoxShadow: 0 0 50px 0 rgba(14, 42, 60, 0.15);
$sm-font-heading: 1.1rem;
$box-shadow: 0 1px 1px rgba(0, 0, 0, 0.11), 0 2px 2px rgba(0, 0, 0, 0.11),
  0 4px 4px rgba(0, 0, 0, 0.11), 0 6px 8px rgba(161, 140, 140, 0.11), 0 8px 16px rgba(0, 0, 0, 0.11);
$box-shadow2: 0 1px 1px rgba(0, 0, 0, 0.11), 0 2px 2px rgba(0, 0, 0, 0.11),
  0 4px 4px rgba(0, 0, 0, 0.11), 0 6px 8px rgba(0, 0, 0, 0.11), 0 8px 16px rgba(0, 0, 0, 0.11);

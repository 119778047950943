@import './overrrides/react_toaster';
@import './config';
@import './themes';
@import './desktopNav';
@import './desktopView';
@import './mixins';


//Default Phone Number input
.phone-input{
  border: 2px solid $light;
  padding: .7rem .2rem;
  border-radius: .3rem;
  input {
    border: none;
    &:focus{
      outline: none;
    }
  }
}
.contactLinks {
  a {
    margin: 0 0.4rem;
  }
}
.contacticons {
  margin: -0.3rem 0 -0.4rem 0;
  color: $secondary2;
}

//**Header components**//
.header {
  background: $white;
}

.appbar {
  z-index: 1;
  &-mtn{
    background: $secondary;
  }
}

.navtabs {
  z-index: 1;
  position: relative;
  ul {
    display: none;
    box-shadow: $cardBoxShadow;
    background-color: $white;
    border-radius: $border-radius;
    z-index: 1;
    width: 14%;
    position: fixed;
    margin: 0.7rem 0 0 -3rem;
    li {
      // border-bottom: 1px solid $light2;
      width: auto;
      padding: 0.4rem;
      a {
        color: $black;
        width: 100%;
        text-transform: uppercase;
        border-bottom: 1px solid $light2;
      }
    }
  }
  &:hover ul {
    display: block;
  }
}
.navbar {
  margin: 0.3rem 0;
  &-user {
    margin-top: 0.4rem;
  }
  &-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    background: $white;
    z-index: 3;
  }
  &-cart {
    margin: -1rem -0.4rem;
  }
  .nav-box {
    display: flex;
    margin: 1rem 0rem 0rem 3rem;
    li {
      margin: 0 0.5rem;
      a {
        color: $black;
      }
    }
    .navbar-cart{
      margin: -.7rem 0 0 0 ;
    }
  }
  .nav-search-bar {
    margin: 0rem auto;
    padding-bottom: 0.3rem;
    width: 96%;
    form {
      margin: 0.3rem auto;
      position: relative;
      width: 100%;
      height: 2.5rem;
      input {
        height: 2.2rem;
        width: 100%;
        border: 1.1px solid $black;
        border-radius: 3rem;
        outline: none;
        background: none;
        padding: 0.9rem;
      }
      button {
        background: none;
        border: none;
        font-size: 1.1rem;
        position: absolute;
        right: 0.5rem;
        top: 0rem;
        margin: 0.4rem 0;
      }
    }
  }
}

.menuicon {
  // position: absolute;
  // right: -0.2rem;
  margin: -0.2rem 0rem 0 -1rem;
}

//****Category*****//

.category {
  text-align: center;
  // margin: 1rem 0.3rem;
  // padding: 0 0.3rem;
  &-list {
    margin: 0.3rem 0;
    img {
      margin: 1rem 0;
      width: 90%;
      border-radius: $border-radius;
    }
  }
}

//****About Page*****//

.about {
  // margin: 0 0.4rem;
  text-align: center;
  &-img {
    img {
      width: 100%;
    }
  }
  &-values {
    margin: 1rem 0 2rem 0;
    p {
      font-weight: 600;
      margin: 0.5rem 0;
    }
  }
  &-desc {
    h3 {
      margin: 2rem 0 1rem 0;
    }
    p {
      margin: 1.8rem 0;
      font-weight: 500;
      padding: 1rem 3rem;
    }
  }
  &-grid {
    div {
      margin: 1rem 0;
      img {
        height: 6rem;
      }
    }
  }
}

//****contatct Page****//

.contact {
  margin: 0 auto;
  align-items: center;
  select {
    height: 3rem;
  }

  form {
    div {
      // width: 70%;
      input {
        &:focus {
          border: $secondary 2px solid;
        }
      }
      button {
        width: 80%;
      }
    }
  }

  &-img {
    text-align: center;
    width: 100%;
    img {
      width: 100%;
    }

    h4 {
      font-size: 1.2rem;
      text-align: center;
    }
  }
  &-imgicon {
    div {
      text-align: center;
      padding: 1rem;

      a {
        color: $black;
      }
    }
  }
  &-content {
    margin: 1rem 0.4rem;
    text-align: center;
    p {
      margin: 1rem 1rem;
      padding: 0.8rem;
    }
  }
  .message-us {
    background: #f4f4f4;
  }

  h3 {
    margin: 1rem;
    text-align: center;
  }

  &-form {
    div {
      margin: 1rem 0;
    }
  }
}


//**Content Components
.mission {
  margin: 3rem 0.5rem;
  text-align: center;
  p {
    margin: 2rem 1rem;
    text-align: justify;
  }
}

.services {
  background: $white;
  color: $black;
  text-align: center;
  margin: 0.5rem auto 0 auto;
  p {
    margin: 0.7rem 0;
  }
  &-grid {
    display: grid;
    grid-template-columns: 1fr;
    margin: 1rem auto;
    justify-content: center;
  }
  &-item {
    border-left: 0.2px solid $light2;
    .item-list {
      & > * {
        margin: 1rem 0.5rem;
      }
      img {
        height: 3rem;
        margin: 0 0.5rem;
      }
    }
    &-desc {
      h4 {
        font-weight: 500;
        margin: 0.7rem 0;
      }
    }
  }
}



//****Help FAQs*****//

.help {
  background: $light2;
  &-title {
    background: $white;
    padding: 0.8rem 0.6rem;
    box-shadow: $box-shadow2;
  }
  &-img {
    background: rgba($primary, 0.7);
    padding: 0.5rem;
    margin: 0.6rem;
    width: 90%;
    p {
      color: $white;
    }
  }
  &-body {
    background: $white;
    box-shadow: $box-shadow;
    margin: 0.7rem;
    border-radius: $border-radius;
    padding: 1rem;
    &-card {
      border-bottom: 1px solid $light2;
      margin: 3rem 0;
      h3 {
        margin: 1rem 0;
        color: $primary;
        font-size: 1.7rem;
      }
      p {
        margin: 0.4rem 0;
        padding: 0.6rem 0;
      }
    }
  }
}

//**Error 404 page***//
.error {
  text-align: center;
  margin: 1rem;
  height: 60vh;
  div {
    h1,
    p,
    a {
      margin: 1rem;
      // text-align: left;
    }
    img {
      height: 7rem;
    }
  }
}

//*****Password ****//
.error-password {
  color: $danger;
  font-size: 0.8rem;
  font-family: Arial, Helvetica, sans-serif;
}

.full-page-loader {
  background-color: #fff;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.full-page-loader > img {
  animation: 1.8s infinite heartbeat;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.05);
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}
